import React, { useState, useMemo } from 'react'
import { Pack, SourceMarketplace } from '../../../../types/packs'
import { Box, Button, Flex, FormControl, Text, Tooltip } from '@chakra-ui/react'
import { calculateRevenue, calculateRoi, ItemData } from '../../utils'
import { CraftingConfig } from './CraftingConfig'

import 'swiper/css'
import 'swiper/css/pagination'
import { InfoIcon } from '@chakra-ui/icons'
import { DESCRPTIONS } from '../../descriptions'

const starAtlasFont = {
    fontFamily: 'RoganRegular',
    fontWeight: 600,
}

interface CalculatorProps {
    gameData: ItemData[];
    onOpenModal: ({ pack, packId }: { pack?: Pack; packId?: string }) => void
}

const HTMLTooltip: React.FC<{ label: string }> = ({ label }) => (
    <div dangerouslySetInnerHTML={{ __html: label }} />
);


const TULLE_FEE = 5

export const Calculator: React.FC<CalculatorProps> = ({ gameData, onOpenModal }) => {
    const craftableItems = useMemo(() => {
        return gameData.filter(item => item.recipe && item.recipe.length > 0);
    }, [gameData]);


    const [selectedItem, setSelectedItem] = useState<string>(craftableItems[0]?.id ?? '');
    const [mode, setMode] = useState<'time' | 'quantity'>('time');
    const [quantity, setQuantity] = useState<number>(1);
    const [time, setTime] = useState<number>(0);
    const [crewMembers, setCrewMembers] = useState<number>(1);
    const [craftingSpeed, setCraftingSpeed] = useState<'normal' | 'reduced'>('normal');
    const [marketplaceFee, setMarketplaceFee] = useState<number>(6);
    const [calculateForBuyPrice, setCalculateForBuyPrice] = useState(false);


    const handleModeChange = () => {
        const details = calculateCraftingDetails()

        if (mode === "time") {
            setMode("quantity")
            setTime(36 * Math.floor(Number(details!.time) / 36))
        } else {
            setMode("time")
            setQuantity(details!.quantity)
        }
    }

    const handleMarketplaceFeeChange = (fee: number) => {
        setMarketplaceFee(fee);
    };
    const handleItemChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedItem(event.target.value);
    };

    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuantity(Number(event.target.value));
    };

    const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTime(Number(event.target.value));
    };

    const handleCrewMembersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCrewMembers(Number(event.target.value));
    };

    const calculateCraftingDetails = () => {
        const item = gameData.find(i => i.id === selectedItem);
        if (!item) return null;

        let calculatedQuantity = quantity;
        let calculatedTime = time;

        if (mode === 'time') {
            const speedMultiplier = craftingSpeed === 'reduced' ? 0.5 : 1;
            calculatedTime = crewMembers > 0 ? (item.craftingDuration || 0) * quantity / (crewMembers * speedMultiplier) : 0;
        } else {
            const speedMultiplier = craftingSpeed === 'reduced' ? 0.5 : 1;
            calculatedQuantity = Math.floor((time * crewMembers * speedMultiplier) / (item.craftingDuration || 1));
        }

        const totalCost = (item.ingredientsCost! * (1 + TULLE_FEE / 100) + item.craftingFee!) * calculatedQuantity;

        return {
            ingredientsCost: item.ingredientsCost! * calculatedQuantity * (1 + TULLE_FEE / 100),
            craftingFee: item.craftingFee! * calculatedQuantity,
            totalCost: totalCost,
            revenue: calculateRevenue(item, calculatedTime * (craftingSpeed === 'reduced' ? 0.5 : 1), crewMembers, TULLE_FEE, marketplaceFee, calculateForBuyPrice),
            time: calculatedTime,
            quantity: calculatedQuantity,
            ingredients: item.recipe?.map(ingredient => ({
                amount: ingredient.amount * calculatedQuantity,
                name: gameData.find(i => i.onChainId === ingredient.mint)?.name || 'Unknown Item'
            }))
        };
    };

    const craftingDetails = useMemo(() => calculateCraftingDetails(), [selectedItem, quantity, time, crewMembers, craftingSpeed, marketplaceFee, calculateForBuyPrice]);

    const formatTime = (seconds: number) => {
        const days = Math.floor(seconds / (24 * 3600));
        const hours = Math.floor((seconds % (24 * 3600)) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        let result = '';
        if (days > 7) {
            return `${days}d`;
        } else if (days > 0) {
            result += `${days}d ${hours}h`;
        } else if (hours > 0) {
            result += `${hours}h ${minutes}m`;
        } else if (minutes > 0) {
            result += `${minutes}m ${remainingSeconds}s`;
        } else {
            result += `${remainingSeconds}s`;
        }

        return result.trim();
    };

    const handleGetCraftingPack = () => {
        const item = gameData.find(i => i.id === selectedItem);
        if (item && craftingDetails) {
            const pack: Pack = {
                id: `${item.id}_crafting_pack`,
                name: `${item.name} Crafting Pack`,
                description: `Crafting pack for ${item.name}`,
                items: item.recipe?.map(ingredient => {
                    const ingredientItem = gameData.find(i => i.onChainId === ingredient.mint);
                    return {
                        details: {
                            id: ingredient.mint,
                            name: ingredientItem?.name || '',
                            onChainId: ingredient.mint,
                            type: 'sft',
                            sourceMarketplace: 'staratlas' as SourceMarketplace,
                            image: ingredientItem?.image || '',
                            url: ingredientItem?.url || '',
                        },
                        quantity: ingredient.amount * craftingDetails.quantity
                    };
                }) || []
            };
            onOpenModal({ pack });
        }
    };

    const selectedItemData = useMemo(() => {
        return gameData.find(item => item.id === selectedItem);
    }, [gameData, selectedItem]);

    const selectBestRoi = () => {
        const bestRoiItem = craftableItems.reduce((best, current) => {
            const currentRoi = calculateRoi(current, TULLE_FEE, marketplaceFee, calculateForBuyPrice);
            const bestRoi = calculateRoi(best, TULLE_FEE, marketplaceFee, calculateForBuyPrice);
            return currentRoi > bestRoi ? current : best;
        });
        setSelectedItem(bestRoiItem.id);
    };

    const selectBestDailyRevenue = () => {
        const bestRevenueItem = craftableItems.reduce((best, current) => {
            const currentRevenue = calculateRevenue(current, 24 * 3600, crewMembers, TULLE_FEE, marketplaceFee, calculateForBuyPrice);
            const bestRevenue = calculateRevenue(best, 24 * 3600, crewMembers, TULLE_FEE, marketplaceFee, calculateForBuyPrice);
            return currentRevenue > bestRevenue ? current : best;
        });
        setSelectedItem(bestRevenueItem.id);
    };

    return (
        <Box borderWidth="1px" borderRadius="lg" p={4}>
            <h2 className="text-amber-300 text-2xl rogan-regular leading-10 tracking-widest uppercase mb-4" style={{ textAlign: 'center', marginBottom: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Crafting calculator
                <Flex ml={2} fontSize={"0.9rem"}>
                    <Tooltip label={DESCRPTIONS.crafting_calculator}  ><InfoIcon /></Tooltip>
                </Flex>
            </h2>
            <Flex direction={["column", "column", "row"]} gap={4}>
                <CraftingConfig
                    craftableItems={craftableItems}
                    selectedItem={selectedItem}
                    mode={mode}
                    quantity={quantity}
                    time={time}
                    crewMembers={crewMembers}
                    craftingSpeed={craftingSpeed}
                    calculateForBuyPrice={calculateForBuyPrice}
                    onItemChange={handleItemChange}
                    onModeChange={handleModeChange}
                    onQuantityChange={handleQuantityChange}
                    onTimeChange={handleTimeChange}
                    onCrewMembersChange={handleCrewMembersChange}
                    onCraftingSpeedChange={setCraftingSpeed}
                    setCalculateForBuyPrice={setCalculateForBuyPrice}
                />
                <Box flex={1}>
                    {craftingDetails && (
                        <Box mt={2} borderWidth="1px" borderRadius="4px" px={3} py={2} alignSelf="flex-end">
                            {selectedItemData && (
                                <Box mb={3} display="flex" flexDirection="column" alignItems="center">
                                    <Text style={{ ...starAtlasFont, fontSize: '1.2rem' }} mb={2} textAlign="center">{selectedItemData.name} Crafting Details</Text>
                                    <img src={selectedItemData.image} alt={selectedItemData.name} style={{ width: '100px', height: '100px' }} />
                                    <Flex direction="column" alignItems="center" mt={2}>

                                        <Box className={calculateRoi(selectedItemData, TULLE_FEE, marketplaceFee) < 0 ? "text-red-500" : "text-amber-300"} style={{ ...starAtlasFont, fontSize: '1.2rem' }} mb={2}>
                                            <Flex alignItems="center">
                                                {`ROI: `}<span style={{ marginLeft: '0.8rem' }}>{`${calculateRoi(selectedItemData, TULLE_FEE, marketplaceFee, calculateForBuyPrice).toFixed(0)}%`}</span>
                                                <Box fontSize={"1.0rem"}>
                                                    <Tooltip label={calculateRoi(selectedItemData, TULLE_FEE, marketplaceFee, calculateForBuyPrice) > 0 ? DESCRPTIONS.roi : DESCRPTIONS.roi_negative}>
                                                        <InfoIcon ml={2} />
                                                    </Tooltip>
                                                </Box>
                                            </Flex>
                                        </Box>

                                        <Box className={calculateRevenue(selectedItemData, 24 * 3600, crewMembers, TULLE_FEE, marketplaceFee) < 0 ? "text-red-500" : "text-amber-300"} style={{ ...starAtlasFont, fontSize: '1.2rem' }}>
                                            <Flex alignItems="center">
                                                {`Daily Revenue:  `}<span style={{ marginLeft: '0.8rem' }}>{`${calculateRevenue(selectedItemData, 24 * 3600 * (craftingSpeed === 'reduced' ? 0.5 : 1), crewMembers, TULLE_FEE, marketplaceFee, calculateForBuyPrice).toFixed(2)} ATLAS`}</span>
                                                <Tooltip label={DESCRPTIONS.daily_revenue}>
                                                    <Box as="span" fontSize="1.0rem" ml={2}>
                                                        <Tooltip label={calculateRoi(selectedItemData, TULLE_FEE, marketplaceFee, calculateForBuyPrice) > 0 ? DESCRPTIONS.daily_revenue : DESCRPTIONS.daily_revenue_negative}>
                                                            <InfoIcon />
                                                        </Tooltip>
                                                    </Box>
                                                </Tooltip>
                                            </Flex>
                                        </Box>

                                    </Flex>
                                </Box>
                            )}
                            <Flex direction="column" mt={16} justifyContent="flex-end" height="100%">
                                <Flex justifyContent="space-between" alignItems="center" mt={2}>
                                    <Text style={{ ...starAtlasFont, fontSize: '1.2rem' }}>Crafting Time:</Text>
                                    <Text style={{ ...starAtlasFont, fontSize: '1.2rem' }}>{formatTime(craftingDetails.time)}</Text>
                                </Flex>
                                <Flex justifyContent="space-between" alignItems="center" mt={2} >
                                    <Text style={{ ...starAtlasFont, fontSize: '1.2rem' }}>Quantity:</Text>
                                    <Text style={{ ...starAtlasFont, fontSize: '1.2rem' }}>{craftingDetails.quantity}</Text>
                                </Flex>
                                <Flex justifyContent="space-between" alignItems="center" mt={2}>
                                    <Text style={{ ...starAtlasFont, fontSize: '1.2rem' }}>Expected Revenue:</Text>
                                    <Text style={{ ...starAtlasFont, fontSize: '1.2rem' }} className={craftingDetails.revenue < 0 ? "text-red-500" : "text-green-500"}>
                                        {craftingDetails.revenue >= 100
                                            ? Math.round(craftingDetails.revenue)
                                            : craftingDetails.revenue.toFixed(2)} ATLAS
                                    </Text>
                                </Flex>
                            </Flex>
                        </Box>
                    )}
                </Box>
            </Flex>



            <Flex>
                <Box flex={1} mr={2} mt={2}>
                    <FormControl mb={1}>
                        <Flex alignItems="center" justifyContent="space-between" style={{ ...starAtlasFont, fontSize: '1.2rem' }}>
                            <Flex alignItems="center">
                                <Text marginRight="0.5rem">Marketplace Fee</Text>
                                <Box fontSize={"1.0rem"}>
                                    <Tooltip label={DESCRPTIONS.marketplace_fee}><InfoIcon /></Tooltip>
                                </Box>
                            </Flex>
                            <Flex>
                                {[6, 5.4, 5.1, 4.5, 3.3, 0.9].map((fee) => (
                                    <Button
                                        key={fee}
                                        onClick={() => handleMarketplaceFeeChange(fee)}
                                        style={{
                                            fontFamily: "RoganRegular",
                                            fontWeight: "bold",
                                            marginLeft: '0.5rem',
                                            padding: '0.25rem 0.5rem',
                                            backgroundColor: marketplaceFee === fee ? '#fcd34d' : '#1e293b',
                                            color: marketplaceFee === fee ? '#000000' : '#ffffff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {fee}%
                                    </Button>
                                ))}
                            </Flex>
                        </Flex>
                    </FormControl>
                </Box>
                <Box flex={1} ml={2} mt={2}>
                    <Flex justifyContent="space-between">
                        <Button onClick={selectBestRoi} className="pack-card-button" flex={1} mr={2} mb={2} fontSize="sm">
                            Best ROI
                        </Button>
                        <Button onClick={selectBestDailyRevenue} className="pack-card-button" flex={1} ml={2} mb={2} fontSize="sm">
                            Best Daily Revenue
                        </Button>
                    </Flex>
                </Box>
            </Flex>

            {craftingDetails && (
                <Box>
                    <Flex borderWidth={1} borderRadius="4px" px={3} py={1} justifyContent="center" mt={2} mb={3}>
                        <Box mt={1} flex={1}>
                            <h3 style={{ ...starAtlasFont, fontSize: '1.2rem' }}>Ingredients Needed:</h3>
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                {craftingDetails.ingredients?.map((ingredient, index) => {
                                    const ingredientItem = gameData.find(i => i.name === ingredient.name);
                                    return (
                                        <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                            {ingredientItem && (
                                                <img
                                                    src={ingredientItem.image}
                                                    alt={ingredient.name}
                                                    style={{ width: '30px', height: '30px', marginRight: '10px' }}
                                                />
                                            )}
                                            <span style={{ ...starAtlasFont, fontSize: '1.2rem' }}>
                                                {ingredient.name}
                                                <span style={{ ...starAtlasFont, fontSize: '1.0rem', marginLeft: '5px' }}>
                                                    (x{ingredient.amount})
                                                </span>
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </Box>
                        <Box mt={1} flex={1}>
                            <h3 style={{ ...starAtlasFont, fontSize: '1.2rem' }}>
                                <Flex alignItems="center">
                                    Crafting Cost:
                                    <Tooltip label={
                                        <HTMLTooltip label={[`Crafting Fee: ${craftingDetails.craftingFee.toFixed(3)} ATLAS,`,
                                        `Ingredients: ${craftingDetails.ingredientsCost.toFixed(3)} ATLAS`].join('<br />')} />
                                    }>
                                        <InfoIcon ml={2} fontSize="1.0rem" />
                                    </Tooltip>
                                </Flex>
                            </h3>
                            <Box mt={2} style={{ ...starAtlasFont, fontSize: '1.2rem' }}>
                                {craftingDetails.totalCost >= 100
                                    ? Math.round(craftingDetails.totalCost)
                                    : craftingDetails.totalCost.toFixed(2)} ATLAS
                            </Box>
                        </Box>
                        <Box mt={1} flex={1}>
                            {calculateForBuyPrice ? (
                                <>
                                    <h3 style={{ ...starAtlasFont, fontSize: '1.2rem' }}>Buy Price:</h3>
                                    <Box mt={2} style={{ ...starAtlasFont, fontSize: '1.2rem' }}>
                                        {(() => {
                                            const value = selectedItemData!.buyPrice.atlas * craftingDetails.quantity;
                                            return value >= 100 ? Math.round(value) : value.toFixed(2);
                                        })()} ATLAS
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <h3 style={{ ...starAtlasFont, fontSize: '1.2rem' }}>Sell Price (lowered by fee):</h3>
                                    <Box mt={2} style={{ ...starAtlasFont, fontSize: '1.2rem' }}>
                                        {(() => {
                                            const value = selectedItemData!.sellPrice.atlas * craftingDetails.quantity * (1 - marketplaceFee / 100);
                                            return value >= 100 ? Math.round(value) : value.toFixed(2);
                                        })()} ATLAS
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Flex>
                    <Flex>
                    </Flex>
                    <Button className="pack-card-button" onClick={handleGetCraftingPack}>
                        Get Crafting Pack
                    </Button>
                </Box >
            )}
        </Box >
    );
};
