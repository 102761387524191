export const DESCRPTIONS = {
  roi: `ROI (Return on Investment) represents the percentage of profit relative to the total cost. It is calculated based on the profit gained from selling the crafted item on the marketplace (including the marketplace fee) at the current market price. The cost refers to the total cost of the materials required to craft the item. Network fees are not included in this calculation.`,
  roi_negative:
    'Negative ROI indicates that crafting the given item will result in a loss if the market prices remain unchanged.',
  daily_revenue: `Daily Revenue is calculated as the profit that can be gained by crafting a specific item with a given number of crew members (by default one). This estimate assumes that the market prices of both the item and its ingredients remain constant.`,
  daily_revenue_negative:
    'Negative Daily Revenue indicates that crafting the given item will result in a loss if the market prices remain unchanged.',
  duration:
    'Duration refers to the time (in seconds) required to create one unit of an item with a single crew member. The shorter the duration, the faster the item is produced. The more crew members you have, the more units you can produce within a given period of time.',
  crafting_calculator:
    "Crafting Calculator helps to estimate time or quantity of items that can be crafted with the given ingredients and crew members and veryfies if it's profitable to craft them.",
  crafting_data:
    'The table shows the most profitable items to craft in Star Atlas based on current market conditions. The sell price is estimated as the average price of the top 1,000 units listed in the marketplace, while the buy price is based on the average of the top 1,000 units from the highest buy offers. Other metrics are calculated using these sell and buy prices. The values for ROI and Daily Revenue are estimated under the assumption that prices will remain constant, though this is not guaranteed.',
  crafting_fee:
    "Crafting Fee refers to the cost associated with manufacturing an item. This fee is displayed in the crafting interface, allowing you to view it in advance. Currently, the Star Atlas team has set the crafting fee at 7% of the item's average Marketplace price over the last 7 days.",
  marketplace_fee: `Marketplace Sell Fee is a fee applied when creating a sell order for items on the Star Atlas Marketplace. Buyers are not charged any fees. By default, this fee is set at 6%, but it can be reduced by staking ATLAS in the locker.`,
  tulle_fee_info: 'Tulle takes 5% convenience fee for Star Atlas packs. ',
  crafting_cost:
    "Crafting Cost includes the price of the items required for crafting as well as the crafting fee. The crafting fee is 7% of the item's average Marketplace price over the last 7 days.",
  crating_speed:
    'If the starbase is not supplied with food or toolkits, its crafting speed is reduced. A 50% reduction applies to starbases of tier 5 or 6.',
  crafting_packs: `Here, you can buy packs with ingredients needed to craft the most profitable items and resupply packs to improve crafting time in your starbase. For more customized packs, visit the crafting calculator tab or ask our chatbot, Theo.`,
  sell_and_buy_price: `Selling at buy price means selling at the highest current bid, offering faster liquidation compared to the sell price. Selling at sell price can maximize profit, but it might take longer to find a buyer, and your offer could be undercut by lower-priced bids.`,
}
