export type ItemData = {
  id: string
  name: string
  onChainId: string
  type: string
  sourceMarketplace: string
  image: string
  url: string
  category: string
  buyPrice: { [key: string]: number }
  sellPrice: { [key: string]: number }
  ingredientsCost: number
  craftingFee?: number
  recipe?: { amount: number; mint: string }[]
  craftingDuration?: number
  loyalityPointsPerUnit?: number
}

export const starAtlasFont = {
  fontFamily: 'RoganRegular',
  fontWeight: 600,
}

export const calculateRevenue = (
  item: ItemData,
  time: number = 24 * 3600,
  crewMembers: number = 1,
  tulleFee: number = 1,
  marketplaceFee: number = 6,
  fromBuyPrice: boolean = false
) => {
  const price = fromBuyPrice
    ? item.buyPrice.atlas!
    : item.sellPrice.atlas * (1 - marketplaceFee / 100)!
  const totalCost = item.ingredientsCost! * (1 + tulleFee / 100) + item.craftingFee!
  const profit = price - totalCost
  const craftingDuration = item.craftingDuration!
  const craftingsPerPeriod = (time / craftingDuration) * crewMembers
  return craftingsPerPeriod * profit
}

export const calculateRoi = (
  item: ItemData,
  tulleFee: number = 1,
  marketplaceFee: number = 6,
  fromBuyPrice: boolean = false
) => {
  const price = fromBuyPrice
    ? 1000 * item.buyPrice.atlas!
    : 1000 * item.sellPrice.atlas * (1 - marketplaceFee / 100)!

  const totalCost = (item.ingredientsCost! * (1 + tulleFee / 100) + item.craftingFee!) * 1000
  const profit = price - totalCost
  const roi = totalCost > 0 ? (profit / totalCost) * 100 : 0
  return roi
}

export const formatTime = (seconds: number) => {
  const days = Math.floor(seconds / (24 * 3600))
  const hours = Math.floor((seconds % (24 * 3600)) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = Math.floor(seconds % 60)

  let result = ''
  if (days > 7) {
    return `${days}d`
  } else if (days > 0) {
    result += `${days}d ${hours}h`
  } else if (hours > 0) {
    result += `${hours}h ${minutes}m`
  } else if (minutes > 0) {
    result += `${minutes}m ${remainingSeconds}s`
  } else {
    result += `${remainingSeconds}s`
  }

  return result.trim()
}
