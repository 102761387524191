import React from 'react'
import { Box, Select, Input, Flex, Radio, Button, Text, Tooltip } from '@chakra-ui/react'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { ItemData } from '../../utils'
import { starAtlasFont } from '../../utils'
import { InfoIcon } from '@chakra-ui/icons'
import { DESCRPTIONS } from '../../descriptions'

interface CraftingConfigProps {
  craftableItems: ItemData[];
  selectedItem: string;
  mode: 'time' | 'quantity';
  quantity: number;
  time: number;
  crewMembers: number;
  craftingSpeed: 'normal' | 'reduced';
  calculateForBuyPrice: boolean;
  onItemChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onModeChange: (newMode: 'time' | 'quantity') => void;
  onQuantityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTimeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCrewMembersChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCraftingSpeedChange: (newSpeed: 'normal' | 'reduced') => void;
  setCalculateForBuyPrice: (value: boolean) => void;
}

export const CraftingConfig: React.FC<CraftingConfigProps> = ({
  craftableItems,
  selectedItem,
  mode,
  quantity,
  time,
  crewMembers,
  craftingSpeed,
  calculateForBuyPrice,
  onItemChange,
  onModeChange,
  onQuantityChange,
  onTimeChange,
  onCrewMembersChange,
  onCraftingSpeedChange,
  setCalculateForBuyPrice,
}) => {
  return (
    <Box flex={1}>
      <FormControl>
        <FormLabel htmlFor="craftableItem" sx={{ ...starAtlasFont, fontSize: '1.2rem' }}>
          Item
        </FormLabel>
        <Select
          id="craftableItem"
          value={selectedItem}
          onChange={onItemChange}
          mb={2}
          style={{ ...starAtlasFont, fontSize: '1.2rem' }}
        >
          {craftableItems.map((item) => (
            <option
              key={item.id}
              value={item.id}
              style={{
                ...starAtlasFont,
                fontSize: '1.2rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {item.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <Flex mb={3}>
        <FormControl as="fieldset" flex={1} mr={2}>
          <FormLabel as="legend" sx={{ ...starAtlasFont, fontSize: '1.2rem' }} mb={3}>
            Mode
          </FormLabel>
          <Flex justifyContent="center">
            <Radio
              value="time"
              isChecked={mode === 'time'}
              onChange={() => onModeChange('time')}
              mr={4}
            >
              Time
            </Radio>
            <Radio
              value="quantity"
              isChecked={mode === 'quantity'}
              onChange={() => onModeChange('quantity')}
            >
              Quantity
            </Radio>
          </Flex>
        </FormControl>

        <FormControl as="fieldset" flex={1} ml={2}>
          <FormLabel as="legend" sx={{ ...starAtlasFont, fontSize: '1.2rem' }} mb={3}>
            <Flex alignItems="center">
              <Text marginRight="0.5rem">Crafting Speed</Text>
              <Box fontSize={"1.0rem"}>
                <Tooltip label={DESCRPTIONS.crating_speed}><InfoIcon /></Tooltip>
              </Box>
            </Flex>
          </FormLabel>
          <Flex justifyContent="center">
            <Radio
              value="normal"
              isChecked={craftingSpeed === 'normal'}
              onChange={() => onCraftingSpeedChange('normal')}
              mr={4}
            >
              Normal
            </Radio>
            <Radio
              value="reduced"
              isChecked={craftingSpeed === 'reduced'}
              onChange={() => onCraftingSpeedChange('reduced')}
            >
              Reduced (50%)
            </Radio>
          </Flex>
        </FormControl>
      </Flex>

      {mode === 'time' ? (
        <FormControl>
          <FormLabel htmlFor="quantity" sx={{ ...starAtlasFont, fontSize: '1.2rem' }}>
            Quantity
          </FormLabel>
          <Input
            id="quantity"
            type="number"
            value={quantity === 0 ? '0' : quantity.toString()}
            onChange={(e) => {
              const value = e.target.value === '' ? 0 : Math.max(0, parseInt(e.target.value));
              if (!isNaN(value) && value >= 0) {
                onQuantityChange(e);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === '-' || e.key === 'e' || e.key === '.') {
                e.preventDefault();
              }
            }}
            min={0}
            step={1}
            mb={3}
          />
        </FormControl>
      ) : (
        <FormControl>
          <FormLabel htmlFor="time" sx={{ ...starAtlasFont, fontSize: '1.2rem' }}>
            Time (hours)
          </FormLabel>
          <Input
            id="time"
            type="number"
            value={time === 0 ? '0' : (time / 3600).toString()} // Convert seconds to hours for display, use empty string if 0
            onChange={(e) => {
              const hours = e.target.value === '' ? 0 : Math.max(0, parseFloat(e.target.value));
              onTimeChange({ target: { value: String(hours * 3600) } } as React.ChangeEvent<HTMLInputElement>);
            }}
            min={0}
            step={0.1} // Allow fractional hours
            mb={3}
          />
        </FormControl>
      )}
      <FormControl>
        <FormLabel htmlFor="crewMembers" sx={{ ...starAtlasFont, fontSize: '1.2rem' }}>
          Crew Members
        </FormLabel>
        <Input
          id="crewMembers"
          type="number"
          value={crewMembers === 0 ? '0' : crewMembers.toString()}
          onChange={(e) => {
            const value = e.target.value === '' ? 0 : Math.max(0, parseInt(e.target.value));
            if (!isNaN(value) && value >= 0) {
              onCrewMembersChange(e);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === '-' || e.key === 'e' || e.key === '.') {
              e.preventDefault();
            }
          }}
          min={0}
          step={1}
          mb={3}
        />
      </FormControl>

      <FormControl as="fieldset" flex={1} mr={2}>
        <FormLabel as="legend" sx={{ ...starAtlasFont, fontSize: '1.2rem' }} mb={3}>
          <Flex alignItems="center">
            <Text marginRight="0.5rem">Calculate ROI and Revenue for</Text>
            <Box fontSize={"1.0rem"}>
              <Tooltip label={DESCRPTIONS.sell_and_buy_price}><InfoIcon /></Tooltip>
            </Box>
          </Flex>
        </FormLabel>
        <Flex justifyContent="center">
          <Radio
            value="buy_price"
            isChecked={calculateForBuyPrice}
            onChange={() => setCalculateForBuyPrice(true)}
            mr={4}
          >
            Buy Price
          </Radio>
          <Radio
            value="quantity"
            isChecked={!calculateForBuyPrice}
            onChange={() => setCalculateForBuyPrice(false)}
          >
            Sell Price
          </Radio>
        </Flex>
      </FormControl>

    </Box>
  )
}
